import React, { Component } from 'react'

import "./MintPage.css"

import BlockView from '../components/BlockView'
import MintView from '../components/MintView'
import RemainedNFTView from '../components/RemainedNFTView'
import SaleDescView from '../components/SaleDescView'
import HeaderView from '../components/HeaderView'
import CaptchaView from '../components/CaptchaView'

import {
    ToastsContainer,
    ToastsStore,
    ToastsContainerPosition
} from 'react-toasts';

import NFTMintingUtil from './NFTMintingUtil'
import Container from 'react-bootstrap/Container'

let pageTitle = process.env.REACT_APP_CURRENT_TITLE_ROUND0;
if (process.env.REACT_APP_CURRENT_ROUND === 1) {
    pageTitle = process.env.REACT_APP_CURRENT_TITLE_ROUND1;
} else if (process.env.REACT_APP_CURRENT_ROUND === 2){
    pageTitle = process.env.REACT_APP_CURRENT_TITLE_ROUND2;
}

class MintPage extends Component {

    constructor(props) {
	super(props)
	this.state = {
	    txType: null,
	    account: '',
	    balance: 0,
	    network: null,
	    mintPrice: 0,
	    currentBlockNumber: 0,
	    mintingStartBlock: 0,
	    mintCount: 1,
	    maxMintAmount: 0,
	    currentMintIndex: 0,
	    captchaValidated: true,
	    mintingStartReady: false,
	    mintLimitPerTRDefault: 0,
	    mintLimitPerWallet: 0,
	    mintPerWallet: 2,
	    isWLOngoing: false,
	    isWLSatisfied: false,
	    isMintingOngoing: false,
	    uncapStartReady: false,
	}
	this.mintUtil = new NFTMintingUtil();
    }

    async startPage() {
	let result;
	try {
	    result = await this.mintUtil.getMintInfo();
	    this.setState(result);
	} catch(err) {
	    console.log(err);
	}
	result = await this.mintUtil.getCurrentBlockNumber();
	this.setState(result);

	await this.updateWalletInfo();
    }

    async updateWalletInfo() {
	let result = await this.mintUtil.isWLOngoing();
	if (result) {
	    this.setState(result);
	}
	if (result.isWLOngoing) {
	    result = await this.mintUtil.isWhitelisted();
	    if (result) {
	    	this.setState(result);
	    }
	}
    }

    componentDidMount() {
	this.startPage();
    }

    handleMintingReady(isReady) {
	this.setState({
	    mintingStartReady: isReady
	});
    }

    handleUncapReady(isReady) {
	// this.setState({
	//     uncapStartReady: isReady
	// });
	// this.mintUtil.setUncapStartReady(isReady);
    }

    async handleMintClick() {
	if (this.mintUtil) {
	    this.setState({
		isMintingOngoing: true,
	    });
	    try {
		this.mintingResult(await this.mintUtil.mintNFT(this.state.mintCount, this.state.mintPrice));
	    } catch(err) {
		console.log(err);
		ToastsStore.error("Minting Fail");
		this.setState({
		    isMintingOngoing: false,
		});
	    }
	}
    }

    handleMintCountChange(mintCount) {
	this.setState({
	    mintCount: mintCount
	});
    }

    handleValidation(captchaValidated) {
	this.setState({
	    captchaValidated: captchaValidated
	});
    }

    async handleWalletConnected(walletUtil, account, balance) {
	if (walletUtil) {
	    this.mintUtil.setWalletUtil(walletUtil);
	    this.setState({
		account: account,
		balance: balance,
	    });
	    await this.updateWalletInfo();
	} else {
	    ToastsStore.error("Wallet is not installed");
	}
    }

    async mintingResult(success) {
	this.setState({
	    isMintingOngoing: false,
	});
	if (success)
	    ToastsStore.success("Minting Success");
	else
	    ToastsStore.error("Minting Fail");
	let walletUtil = this.mintUtil.walletUtil;
	if (walletUtil) {
	    let result = await walletUtil.loadAccountInfo();
	    if (result.success) {
		this.setState({
		    account: result.account,
		    balance: result.balance,
		})
	    }
	}
	this.startPage();
    }

    render() {
	const {
	    account, balance,
	    currentBlockNumber, mintingStartBlock,
	    mintPrice, maxMintAmount,
	    currentMintIndex, mintCount,
	    captchaValidated, mintingStartReady,
	    mintLimitPerTRDefault, mintLimitPerWallet,
	    mintPerWallet,
	    isWLOngoing,
	    isWLSatisfied,
	    isMintingOngoing, uncapStartReady
	} = this.state;

	return (
	    <div>
		<ToastsContainer
		    position={ToastsContainerPosition.BOTTOM_CENTER}
		    store={ToastsStore}
		    lightBackground/>
		<HeaderView
		    account={account}
		    balance={balance}
		    walletConnected={this.handleWalletConnected.bind(this)}/>
		<Container fluid="md">
		    <div className="main-card">
			<h1>
		            {pageTitle}
			</h1>
			<BlockView
			    currentBlockNumber={currentBlockNumber}
			    mintingStartBlock={mintingStartBlock}
			    handleMintingReady={this.handleMintingReady.bind(this)}
			    handleUncapReady={this.handleUncapReady.bind(this)}/>
			<hr/>
			<RemainedNFTView
			    maxMintAmount={maxMintAmount}
			    currentMintIndex={currentMintIndex}
			    mintPrice={mintPrice}/>
			<hr/>
			<SaleDescView
	                    mintPrice={mintPrice}
	                    isWLOngoing={isWLOngoing}
	                    isWLSatisfied={isWLSatisfied}
	                    mintLimitPerTRDefault={mintLimitPerTRDefault}
	                    mintLimitPerWallet={mintLimitPerWallet}
	                    uncapStartReady={uncapStartReady}
			    mintPerWallet={mintPerWallet}/>
			<hr/>
			<MintView
			    mintCount={mintCount}
			    mintingStartReady={mintingStartReady}
			    captchaValidated={captchaValidated}
			    handleClick={this.handleMintClick.bind(this)}
			    handleMintCountChange={this.handleMintCountChange.bind(this)}
			    account={account}
			    balance={balance}
			    isWLOngoing={isWLOngoing}
			    isWLSatisfied={isWLSatisfied}
			    remainedCount={maxMintAmount - currentMintIndex}
			    mintLimitPerTRDefault={mintLimitPerTRDefault}
			    mintLimitPerWallet={mintLimitPerWallet}
			    isMintingOngoing={isMintingOngoing}
			    uncapStartReady={uncapStartReady}/>
			<CaptchaView
			    captchaValidated={captchaValidated}
			    handleValidation={this.handleValidation.bind(this)}/>
		    </div>
		    <div>
			<p className="other-info">
			    For more detailed information about the current block,<br/> please refer to <a href="https://scope.klaytn.com/" target="_blank">Klayscope</a> or <a href="https://klayswap.com/" target="_blank">Klayswap</a>.
			</p>
		    </div>
		</Container>

	    </div>
	)
    }
};

export default MintPage
