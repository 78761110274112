import React, { Component } from 'react'

import "./MintPage.css"

import BlockView from '../components/BlockView'
import MintView from '../components/MintView'
import RemainedNFTView from '../components/RemainedNFTView'
import SaleDescView from '../components/SaleDescView'
import HeaderView from '../components/HeaderView'
import CaptchaView from '../components/CaptchaView'

import Button from 'react-bootstrap/Button'

import {
    ToastsContainer,
    ToastsStore,
    ToastsContainerPosition
} from 'react-toasts';

import NFTMintingUtil from './NFTMintingUtil'
import Container from 'react-bootstrap/Container'

let pageTitle = process.env.REACT_APP_CURRENT_TITLE_ROUND0;
if (process.env.REACT_APP_CURRENT_ROUND === 1) {
    pageTitle = process.env.REACT_APP_CURRENT_TITLE_ROUND1;
} else if (process.env.REACT_APP_CURRENT_ROUND === 2){
    pageTitle = process.env.REACT_APP_CURRENT_TITLE_ROUND2;
}

class FreeMintPage extends Component {

    constructor(props) {
	super(props)
	this.state = {
	    account: '',
	    balance: 0,
	    dailySalesCount: 0,
	    remainingCount: 0,
	    isWhitelist: false,
	    isNotMinted: false,
	    isMintingOngoing: false,
	}
	this.mintUtil = new NFTMintingUtil();
    }

    async startPage() {
	let result;
	try {
	    result = await this.mintUtil.getFreeMintInfo();
	    this.setState(result);
	} catch(err) {
	    console.log(err);
	}
	await this.updateWalletInfo();
    }

    async updateWalletInfo() {
	let result = await this.mintUtil.isFreeMintEnabled();
	if (result) {
	    this.setState(result);
	}
    }

    componentDidMount() {
	this.startPage();
    }

    async handleMintClick() {
	if (this.mintUtil) {
	    this.setState({
		isMintingOngoing: true,
	    });
	    try {
		this.mintingResult(await this.mintUtil.mintFreeNFT());
	    } catch(err) {
		console.log(err);
		ToastsStore.error("Minting Fail");
		this.setState({
		    isMintingOngoing: false,
		});
	    }
	}
    }

    async handleWalletConnected(walletUtil, account, balance) {
	if (walletUtil) {
	    this.mintUtil.setWalletUtil(walletUtil);
	    this.setState({
		account: account,
		balance: balance,
	    });
	    await this.updateWalletInfo();
	} else {
	    ToastsStore.error("Wallet is not installed");
	}
    }

    async mintingResult(success) {
	this.setState({
	    isMintingOngoing: false,
	});
	if (success)
	    ToastsStore.success("Minting Success");
	else
	    ToastsStore.error("Minting Fail");
	let walletUtil = this.mintUtil.walletUtil;
	if (walletUtil) {
	    let result = await walletUtil.loadAccountInfo();
	    if (result.success) {
		this.setState({
		    account: result.account,
		    balance: result.balance,
		})
	    }
	}
	this.startPage();
    }

    render() {
	const {
	    account,
	    balance,
	    dailySalesCount,
	    remainingCount,
	    isWhitelist,
	    isNotMinted,
	    isMintingOngoing,
	} = this.state;
	console.log();
	let digitViews = String(remainingCount).split("").map(
	    (digit, index) => (
		<span
		    className="number-card"
		    key={"d-" + index}>
		    {digit}
		</span>
	    )
	);
	let mintButton = (
	    <Button
		variant="primary"
		className="btn-mint"
		disabled={false}
		onClick={this.handleMintClick.bind(this)}>
		START MINTING
	    </Button>
	);
	if (!account) {
	    mintButton = (
		<Button
		    variant="primary ready"
		    className="btn-mint"
		    disabled={true}>
		    CONNECT WALLET
		</Button>
	    );
	} else if (isMintingOngoing) {
	    mintButton = (
		<Button
		    variant="primary ready"
		    className="btn-mint"
		    disabled={true}>
		    MINTING...
		</Button>
	    );
	} else if (!isWhitelist) {
	    mintButton = (
		<Button
		    variant="primary ready"
		    className="btn-mint"
		    disabled={true}>
		    NOT WHITELISTED
		</Button>
	    );
	} else if (!isNotMinted) {
	    mintButton = (
		<Button
		    variant="primary ready"
		    className="btn-mint"
		    disabled={true}>
		    ALREADY MINTED
		</Button>
	    );
	} else if (remainingCount <= 0) {
	    mintButton = (
		<Button
		    variant="primary ready"
		    className="btn-mint"
		    disabled={true}>
		    SOLD OUT
		</Button>
	    );
	}
	return (
	    <div>
		<ToastsContainer
		    position={ToastsContainerPosition.BOTTOM_CENTER}
		    store={ToastsStore}
		    lightBackground/>
		<HeaderView
		    account={account}
		    balance={balance}
		    walletConnected={this.handleWalletConnected.bind(this)}/>
		<Container fluid="md">
		    <div className="main-card freemint-card">
			<img className="title-img" src="freemint_title.png" alt=""/>
			<img className="subtitle-img" src="freemint_subtitle.png" alt=""/>
			<div className="nft-info">
			    <p className="remaining-nfts">REMAINING NFTS</p>
			    <div>
				{digitViews}
			    </div>
			</div>
			<div className="info-line">
			    <p>Price</p>
			    <h1>FREE</h1>
			</div>
			<div className="info-line">
			    <p>PER WALLET</p>
			    <h1>MAX 1EA</h1>
			</div>
			<div className="mint-line">
			    {mintButton}
			</div>
		    </div>
		    <div>
			<p className="other-info freemint">
			    • You can acquire a LAND3 Voucher through this event.
			</p>
			<p className="other-info freemint">
			    • It can be used to obtain your desired location of LAND3 on the LAND3 website, which opens in June 2023.
			</p>
		    </div>
		</Container>

	    </div>
	)
    }
};

export default FreeMintPage
