import React, { Component } from 'react';

import ReCAPTCHA from "react-google-recaptcha";

const RECAPTCHA_APP_KEY = "6Lc4vLYeAAAAAOhYgBDjxAbIq8VIrbBDolF7kuO2";

class CaptchaView extends Component {

    constructor(props) {
	super(props)
	this.handleValidation
	    = props.handleValidation;
	this.recaptchaRef = React.createRef();
	let captchaTimeout = localStorage.getItem('captchaTimeout');
	if (Date.now() - captchaTimeout < 2 * 60 * 1000) {
	    this.handleValidation(true);
	}
    }

    onChange(humanKey) {
	this.handleValidation(true);
	localStorage.setItem("captchaTimeout", Date.now());
    }
    
    render() {
	if (this.props.captchaValidated) {
	    return "";
	}
	return (
	    <div className="container-captcha">
		<ReCAPTCHA
		    ref={this.recaptchaRef}
		    sitekey={RECAPTCHA_APP_KEY}
		    onChange={this.onChange.bind(this)}
		/>
	    </div>
	);
    };
}

export default CaptchaView;
