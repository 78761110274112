import { web3ZK } from './zksync'
import {
    caver,
    caverKAS,
    initKASAPI,
} from './caver';
import Web3 from 'web3';

function sleep(t){
   return new Promise(resolve=>setTimeout(resolve,t));
}

let chainId = process.env.REACT_APP_CHAIN_ID_PRO;
let chainName = process.env.REACT_APP_CHAIN_NAME_PRO;
let rpcUrl = process.env.REACT_APP_RPC_URL_PRO;
let exUrl = process.env.REACT_APP_EX_URL_PRO;
if (process.env.REACT_APP_MODE === "dev") {
    chainId = process.env.REACT_APP_CHAIN_ID_DEV;
    chainName = process.env.REACT_APP_CHAIN_NAME_DEV;
    rpcUrl = process.env.REACT_APP_RPC_URL_DEV;
    exUrl = process.env.REACT_APP_EX_URL_DEV;
}

class KlaytnWalletUtil {

    constructor(contractsSetup) {
	this.contractsSetup = contractsSetup;
	this.contracts = {};
	for (let name of Object.keys(contractsSetup)) {
	    this.contracts[name] = this.initContract(
		name,
		contractsSetup[name].address,
		contractsSetup[name].abi,
	    )
	}
    }

    initContract(name, address, abi) {
	return {
	    name: undefined
	};
    }

    async connectWallet() {
	return {
	    success: false,
	    account: "",
	    balance: "",
	}
    }

    getSelectedAccount() {
	return undefined;
    }

    async getBlockNumber() {
	return await web3ZK.eth.getBlockNumber();
    }

}


export class MetamaskWalletUtil extends KlaytnWalletUtil {

    initContract(name, address, abi) {
	this.web3 = new Web3(window.ethereum);
	this.enabled = false;
	return new this.web3.eth.Contract(
	    abi,
	    address
	);
    }

    async connectWallet() {
	if (window.ethereum) {
	    const accounts
		  = await window.ethereum.request(
		      {
			  method: 'eth_requestAccounts'
		      }
		  );
	    window.ethereum.selectedAddress
		= accounts[0];
	    this.enabled = true;
	    await this.switchEthereumChain();
	    return true;
	}
	this.enabled = false;
	return false;
    }

    async switchEthereumChain() {
	try {
	    await window.ethereum.request({
		method: 'wallet_switchEthereumChain',
		params: [{ chainId: chainId }],
	    });
	} catch (e) {
	    if (e.code === 4902) {
		try {
		    await window.ethereum.request({
			method: 'wallet_addEthereumChain',
			params: [
			    {
				chainId: chainId,
				chainName: chainName,
				nativeCurrency: {
				    name: 'Ethereum',
				    symbol: 'ETH', // 2-6 characters long
				    decimals: 18
				},
				blockExplorerUrls: [exUrl],
				rpcUrls: [rpcUrl],
			    },
			],
		    });
		} catch (addError) {
		    console.error(addError);
		}
	    }
	    // console.error(e)
	}
    }

    async loadAccountInfo() {
	let balance = await this.web3.eth.getBalance(window.ethereum.selectedAddress);
	balance
	    /= 1000000000000000000;
	return {
	    success: true,
	    account: window.ethereum.selectedAddress,
	    balance: balance,
	}
    }

    async getSelectedAccount() {
	return window.ethereum.selectedAddress;
    }

    async sendTransaction(
	contractName,
	methodName,
	from,
	to,
	value,
	args,
    ) {
	let response = await this.contracts.mint.methods.landMint
	    .apply(this, args)
	    .send({
		type: "0",
	    	from: from,
	    	to: to,
	    	value: value,
	    });
	if (response.status) {
	    return true;
	}
	return false;
    }

    async logout() {
	let account = await this.getSelectedAccount();
	if (account) {
	    this.web3.eth.personal.lockAccount(account);
	}
    }
}

export class KaikasWalletUtil extends KlaytnWalletUtil {

    initContract(name, address, abi) {
	initKASAPI()
	if (!this.contractsKAS) this.contractsKAS = {};
	this.contractsKAS[name] = new caverKAS.contract(
	    abi,
	    address
	);
	return new caver.contract(
	    abi,
	    address
	);
    }

    async connectWallet() {
	if (window.klaytn) {
	    const { klaytn } = window;
	    this.klaytn = klaytn;
	    if (this.klaytn) {
		try {
		    let result
			= await this.klaytn.enable();
		    if (!this.klaytn.selectedAddress) {
			this.klaytn.selectedAddress = result[0];
		    }
		    this.enabled = true;
		} catch (error) {

		    return false;
		}
	    } else {
		return false;
	    }
	    return true;
	}
	return false;
    }

    async loadAccountInfo() {
	try {
	    let balance = await caver.klay.getBalance(window.klaytn.selectedAddress);
	    let cnt = 0;
	    while (balance < 0) {
		balance = await caver.klay.getBalance(window.klaytn.selectedAddress);
		cnt++;
		await sleep(1000);
		if (cnt === 10) {
		    break;
		}
	    }
	    balance
		= caver.utils.fromPeb(balance, 'ETH');
	    if (balance < 0) {
		return {success: false};
	    }
	    return {
		success: true,
		account: window.klaytn.selectedAddress,
		balance: balance,
	    }
	} catch(error) {
	    console.log(error);
	}
    }

    async getSelectedAccount() {
	return await window.klaytn.selectedAddress;
    }

    async sendTransaction(
	contractName,
	methodName,
	from,
	to,
	value,
	args,
    ) {
	let promise = new Promise((resolve, reject) => {
	    let mintABI = undefined;
	    for (let abi of this.contractsSetup[contractName].abi) {
		if (
		    abi.name === methodName &&
			abi.inputs.length === args.length
		) {
		    mintABI = abi;
		    break;
		}
	    }
	    const data = caver
		  .klay.abi.encodeFunctionCall(
		      mintABI,
		      args
		  );
	    caver.klay
		.sendTransaction({
		    type: 'SMART_CONTRACT_EXECUTION',
		    from: from,
		    to: to,
		    data: data,
		    gas: 3000000,
		    value: value
		}).on('receipt', receipt => {
		    // console.log(receipt);
		    if (receipt.status) {
			resolve(true);
		    } else {
			resolve(false);
		    }
		}).on('error', error => {
		    // console.log(error);
		    resolve(false);
		});
	});
	return promise;
    }

    async logout() {
	let account = await this.getSelectedAccount();
	if (account) {
	    caver.klay.personal.lockAccount(account);
	}
    }

}

export default KlaytnWalletUtil;
