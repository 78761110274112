import React from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const RemainedNFTView = ({
    maxMintAmount,
    currentMintIndex,
    mintPrice,
}) => {
    let remainedAmount =
	maxMintAmount - currentMintIndex;
    let mintPriceInKlay
	= mintPrice/1000000000000000000;
    return (
	<Row>
	    <Col xs={6}>
		<div>
		    <h3>
			NFT 잔여수량<br/>
			<span>Remaining</span>
		    </h3>
		    <h2 className="content">
	    {maxMintAmount > 0 ? remainedAmount.toLocaleString() : "-"}
		    </h2>
		</div>
	    </Col>
	    <Col xs={6}>
		<div>
		    <h3>
			가격<br/>
			<span>Price</span>
		    </h3>
		    <h2 className="content">
			<span className="ic-klaytn">
			</span>
			{mintPriceInKlay.toLocaleString()} ETH
		    </h2>
		</div>
	    </Col>
	</Row>
    )
}
export default RemainedNFTView
