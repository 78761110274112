import React from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const SaleDescView = ({
    mintPrice,
    isWLOngoing,
    isWLSatisfied,
    mintLimitPerTRDefault,
    mintLimitPerWallet,
    uncapStartReady,
    mintPerWallet,
}) => {
    let mintLimitPerTRFinal = mintLimitPerTRDefault;
    if (isWLOngoing && isWLSatisfied) {
	if (mintLimitPerWallet < mintLimitPerTRDefault) {
	    mintLimitPerTRFinal = mintLimitPerWallet;
	}
    }
    if (uncapStartReady) {
	mintLimitPerTRFinal = mintLimitPerTRDefault;
    }
    return (
    <Row>
	<Col xs={6}>
	    <div>
		<h3>
		    트랜젝션<br/>
		    <span>PER TRANSACTION</span>
		</h3>
		<div className="content size-big">
		    최대 {mintLimitPerTRFinal}개
		</div>
		<div className="content size-small">MAX {mintLimitPerTRFinal} UNITS</div>
	    </div>
	</Col>
	<Col xs={6}>
	    <div>
		<h3>
		    월렛<br/>
		    <span>PER WALLET</span>
		</h3>
		<div className="content size-big">
		    {mintPerWallet === '-1' ? 'Unlimited' : '최대 ' + mintPerWallet + ' 개'}
		</div>
		<div className="content size-small">{mintPerWallet === '-1' ? 'Unlimited' : 'MAX ' + mintPerWallet + ' UNITS'}</div>
	    </div>
	</Col>
    </Row>
    )
}
export default SaleDescView
