import React from 'react'

import Web3 from 'web3';

import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

import './KlaytnWalletSelector.css'

import {
    MetamaskWalletUtil,
    KaikasWalletUtil,
} from './KlaytnWalletUtil'

const modalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)'
    },
    content: {
	top: '50%',
	left: '50%',
	right: 'auto',
	bottom: 'auto',
	marginRight: '-50%',
	transform: 'translate(-50%, -50%)',
	padding: 0,
	borderRadius: '16px',
	overflow: 'auto',
    },
};

class KlaytnWalletSelector extends React.Component {

    constructor(props) {
	super(props);
	this.state = {
	    walletList: [],
	    isSelectorModalOpen: false,
	    selectedAccount: undefined,
	    selectedWallet: "Kaikas"
	};
    }

    componentDidMount() {
	this.initSelector();
	const that = this;
	if (window.klaytn) {
	    window.klaytn.on('accountsChanged', function(accounts) {
		window.klaytn.selectedAddress = accounts[0];
		that.initSelector();
	    });
	}
	if (window.ethereum) {
	    window.ethereum.on('connect', function(accounts) {
		window.ethereum.selectedAddress = accounts[0];
		that.initSelector();
	    });
	    window.ethereum.on('accountsChanged', function(accounts) {
		that.initSelector();
	    });
	}
    }

    async initSelector() {
	let walletList
	    = await this.getAvailableWalletList();
	let found = false;
	for (let wallet of walletList) {
	    if (wallet.account
		&& this.state.selectedWallet === wallet.name) {
		await this.initWalletUtil(
		    wallet.name
		);
		found = true;
		break;
	    }
	}
	if (!found) {
	    for (let wallet of walletList) {
		if (wallet.account) {
		    await this.initWalletUtil(
			wallet.name
		    );
		    break;
		}
	    }
	}
	this.setState({
	    "walletList": walletList,
	});
    }

    async getAvailableWalletList() {
	let walletList = [];
	let kaikasWallet = {
	    name: "Kaikas",
	    icon: "ic-kaikas",
	    account: undefined,
	}
	let metamaskWallet = {
	    name: "Metamask",
	    icon: "ic-metamask",
	    account: undefined,
	}

	let provider = localStorage.getItem('selectedProvider')
	if (provider === "Kaikas") {
	    if (window.klaytn) {
		kaikasWallet.account = window.klaytn.selectedAddress;
	    }
	} else if (provider === "Metamask") {
	    if (window.ethereum) {
		let web3 = await new Web3(window.ethereum);
		metamaskWallet.account = window.ethereum.selectedAddress;
	    }
	}
	// walletList.push(kaikasWallet);
	walletList.push(metamaskWallet);
	return walletList;
    }

    onConnectButtonClick() {
	if (!this.state.isSelectorModalOpen) {
	    this.setState({
		isSelectorModalOpen: true,
	    });
	} else {
	    this.setState({
		isSelectorModalOpen: false,
	    });
	}
    }

    closeSelectorModal(name) {
	this.setState({
	    isSelectorModalOpen: false,
	});
	if (name === 'logout') {
	    localStorage.setItem('selectedProvider', '')
	    this.logout()
	    this.props.walletConnected(
		this.walletUtil,
		this.state.selectedAccount,
		0
	    );
	    return
	}
	localStorage.setItem('selectedProvider', name)
	this.initWalletUtil(name);
    }

    logout() {
	this.setState({ selectedAccount: undefined });
	for (let wallet of this.state.walletList) {
	    wallet.account = undefined
	}
    }

    async initWalletUtil(name) {
	if (name === "Metamask") {
	    this.walletUtil = new MetamaskWalletUtil(
		this.props.contractsSetup
	    );
	    this.setState({
		selectedWallet: "Metamask"
	    });
	    if (await this.walletUtil.connectWallet()) {
		await this.loadAccountInfo();
		if (this.props.walletSelected) {
		    this.props.walletSelected(
			this.walletUtil
		    );
		}
	    } else {
		if (this.props.walletConnected) {
		    this.props.walletConnected(
			undefined
		    );
		}
	    }
	} else if (name === "Kaikas") {
	    this.walletUtil = new KaikasWalletUtil(
		this.props.contractsSetup
	    );
	    this.setState({
		selectedWallet: "Kaikas"
	    });
	    if (await this.walletUtil.connectWallet()) {
		await this.loadAccountInfo();
		if (this.props.walletSelected) {
		    this.props.walletSelected(
			this.walletUtil
		    );
		}
	    } else {
		if (this.props.walletConnected) {
		    this.props.walletConnected(
			undefined
		    );
		}
	    }
	}
    }

    async loadAccountInfo() {
	let result = await this.walletUtil.loadAccountInfo();
	if (!result.success) return;
	this.setState({
	    selectedAccount: result.account,
	});
	this.props.walletConnected(
	    this.walletUtil,
	    result.account,
	    result.balance
	);
    }

    getShortenedAddress() {
	if (this.state.selectedAccount) {
	    return this.state.selectedAccount.substring(0,8) + "..." + this.state.selectedAccount.substring(37);
	}
	return ""
    }

    checkLogin() {
	let provider = localStorage.getItem('selectedProvider')
	if (provider && provider !== '') {
	    if (provider === "Kaikas") {
		if (window.klaytn && window.klaytn.selectedAddress) {
		    return true;
		}
	    } else if (provider === "Metamask") {
		if (window.ethereum && window.ethereum.selectedAddress) {
		    return true;
		}
	    }
	}
	return false;
    }

    render() {
	let walletList=this.state.walletList.map((wallet,index)=>{
	    return (
		<li key={index}>
		    <div className="btn-select-wallet">
			<a href="#" onClick={this.closeSelectorModal.bind(this, wallet.name)}>
			    <span className={wallet.icon}>
			    </span>
			    {wallet.name}
			</a>
		    </div>
		</li>
	    );
	});
	let buttonContent = "CONNECT WALLET";

	let walletIcon = (
	    <span className="ic-metamask">
	    </span>
	);
	let isLogin = this.checkLogin()
	let provider = localStorage.getItem('selectedProvider')
	if (provider === 'Kaikas') {
	    walletIcon = (
		<span className="ic-kaikas">
		</span>
	    );
	}

	let header = (
	    <div className="modal-header">
		<h3>지갑을 연결해주세요</h3>
		<p>Connect your wallet.</p>
	    </div>
	);
	if (isLogin) {
	    walletList = (
		<li>
		    <div className="btn-select-wallet">
			<a href="#" onClick={this.closeSelectorModal.bind(this, 'logout')}>
			    Logout
			</a>
		    </div>
		</li>
	    );
	    let address = this.getShortenedAddress();
	    header = (
		<div className="modal-header">
		    <h3>Connected Wallet</h3>
		    <p>{address}</p>
		</div>
	    )
	    if (this.state.selectedAccount) {
		buttonContent = this.getShortenedAddress();
	    }
	}
	return (
	    <Button
		variant="light"
		onClick={this.onConnectButtonClick.bind(this)}
		className="btn-klaytn-wallet">
		{ walletIcon }
		{buttonContent}
		<Modal
		    isOpen={this.state.isSelectorModalOpen}
		    onRequestClose={this.state.closeSelectorModal}
		    ariaHideApp={false}
		    style={modalStyles}
		    contentLabel="Example Modal">
		    <div className="modal-wallets">
			{header}
			<ul className="list-wallets">
			    {walletList}
			</ul>
		    </div>
		</Modal>
	    </Button>
	);
    }
}

export default KlaytnWalletSelector;
