import Caver from 'caver-js'
export const caver = new Caver(window.klaytn);

const CaverExtKAS = require('caver-js-ext-kas');
export const caverKAS = new CaverExtKAS();

export function initKASAPI() {
    // let chainId = process.env.REACT_APP_CHAIN_ID;
    // let accessKey = process.env.REACT_APP_KAS_AUTHORIZATION.split(":")[0];
    // let secretKey = process.env.REACT_APP_KAS_AUTHORIZATION.split(":")[1];
    // caverKAS.initKASAPI(
    // 	chainId,
    // 	accessKey,
    // 	secretKey,
    // );
}
