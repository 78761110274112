import ContractABI from './ContractABI.json';
import Web3 from "web3";

import {
    web3ZK
} from '../klaytn/zksync'

export function getCurrentRound() {
    return Number(process.env.REACT_APP_CURRENT_ROUND);
}

let contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS_PRO;
if (process.env.REACT_APP_MODE === "dev") {
    contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS_DEV;
}

export function getContractsSetup() {
    return {
	mint: {
	    abi: ContractABI.abi,
	    address: contractAddress,
	},
	wl: {
	    abi: ContractABI.abi,
	    address: contractAddress,
	}
    };

}

export function getKASAuthorization() {
    return 'Basic ' + btoa(process.env.REACT_APP_KAS_AUTHORIZATION);
}

class NFTMintingUtil {

    constructor(walletUtil) {
	this.walletUtil = walletUtil;
	this.currentRound = getCurrentRound();
	this.kasAuthorization
	    = getKASAuthorization();
    }

    setWalletUtil(walletUtil) {
	this.walletUtil = walletUtil;
    }

    setUncapStartReady(isReady) {
	if (isReady) this.currentRound = getCurrentRound() + 1;
    }

    async getMintInfo() {
	let contractOBJ = await new web3ZK.eth.Contract(ContractABI.abi, contractAddress);

    	const info = await contractOBJ.methods.getMintInfo(this.currentRound).call();
	let mintingStartBlock = Number(info[0]);
	let mintPrice = Number(info[1]);
	let mintLimitPerTRDefault = Number(info[2]);
    	let maxMintAmount = Number(info[3]);
	// TODO
	let mintPerWallet = Number(info[5]);
    	let currentMintIndex = Number(info[6]);

    	return {
    	    maxMintAmount: maxMintAmount,
    	    mintPrice: mintPrice,
    	    currentMintIndex: currentMintIndex,
	    mintingStartBlock: mintingStartBlock,
	    mintLimitPerTRDefault: mintLimitPerTRDefault,
	    mintPerWallet: mintPerWallet,
    	};
    }

    async getFreeMintInfo() {
	let contractOBJ = await new web3ZK.eth.Contract(ContractABI.abi, contractAddress);
	const receipt = await contractOBJ.methods
	      .getFreeMintLeftover()
	      .call();
	let prevMaxCount = Number(receipt[0]);
	let currMaxCount = Number(receipt[1]);
	let mintingIndex = Number(receipt[2]);
	let dailySalesCount = currMaxCount - prevMaxCount;
	let remainingCount = currMaxCount - mintingIndex + 1;
	console.log(receipt);
	return {
	    dailySalesCount: dailySalesCount,
	    remainingCount: remainingCount,
	}
    }

    async isFreeMintEnabled() {
	if (this.walletUtil) {
	    let account
	    = await this.walletUtil.getSelectedAccount();
	    console.log(account);
	    if (account) {
		let contractOBJ = await new web3ZK.eth.Contract(ContractABI.abi, contractAddress);
		const isWhitelist = await contractOBJ.methods
		      .isFreeMintWhiteList(account)
		      .call();
		const isNotMinted = await contractOBJ.methods
		      .getFreeMintEligible(account)
		      .call();
		return {
		    isWhitelist: isWhitelist,
		    isNotMinted: isNotMinted,
		}
	    }
	}
	return undefined;
    }

    async mintFreeNFT() {
	let account = await this.walletUtil.getSelectedAccount();

	let contractOBJ = await new web3ZK.eth.Contract(ContractABI.abi, contractAddress);
	let gas_price = await web3ZK.eth.getGasPrice();
	contractOBJ.setProvider(window.ethereum);
	const receipt = await contractOBJ.methods
	      .freeMint()
	      .send({
		  from: account,
		  gasPrice: gas_price
	      });
	return receipt && receipt.status
    }

    async isWLOngoing() {
	if (this.walletUtil) {
	    // let walletUtil = new KaikasWalletUtil(getContractsSetup());

	    // let contract
	    // 	= walletUtil.contractsKAS.wl;
	    // let n_wl
	    // 	= await contract.methods.getWhiteListOngoing(
	    // 	    this.currentRound
	    // 	).call();
	    // return {
	    // 	isWLOngoing: n_wl
	    // };
	    return {
		isWLOngoing: false
	    };
	}
	return {
	    isWLOngoing: false
	};
    }


    async isWhitelisted() {
	let contractOBJ = await new web3ZK.eth.Contract(ContractABI.abi, contractAddress);
	let mintLimitPerWallet
	    = await contractOBJ.methods.isWhiteList(
		await this.walletUtil.getSelectedAccount()
	    ).call();
    	let ret = {
	    isWLSatisfied: (mintLimitPerWallet > 0),
    	    mintLimitPerWallet: mintLimitPerWallet
	};
	return ret;
    }

    async mintNFT(mintCount, mintPrice) {
	if (mintCount < 1) return false;
	return await this.walletUtil.sendTransaction(
	    "mint",
	    "mintSNKRZ",
	    await this.walletUtil.getSelectedAccount(),
	    this.walletUtil.contractsSetup.mint.address,
	    String(mintCount * mintPrice),
	    [
		this.currentRound,
		mintCount,
	    ]
	);
    }

    async getCurrentBlockNumber() {
	return {
	    currentBlockNumber: await web3ZK.eth.getBlockNumber(),
	}
    }
}

export default NFTMintingUtil;
