import React from 'react';

import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'

import "./HeaderView.css"

import KlaytnWalletSelector from '../klaytn/KlaytnWalletSelector';
import {
    getContractsSetup
} from '../pages/NFTMintingUtil.js'

class HeaderView extends React.Component {
    
    constructor(props) {
	super(props);
	this.contractsSetup = getContractsSetup();
    }
    
    render() {
	return (
	    <Navbar className="navbar">
		<Container fluid="md">
		    <Navbar.Brand href="/" className="logo"></Navbar.Brand>
		    <Navbar.Toggle />
		    <Navbar.Collapse className="justify-content-end">
			<KlaytnWalletSelector
	                    contractsSetup={this.contractsSetup}
			    walletConnected={this.props.walletConnected}/>
		    </Navbar.Collapse>
		</Container>
	    </Navbar>	    
	);
    }
}


export default HeaderView
