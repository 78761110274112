import React from 'react';

import Row from 'react-bootstrap/Row'

import {
    KaikasWalletUtil,
} from '../klaytn/KlaytnWalletUtil';

import {
    getContractsSetup,
} from '../pages/NFTMintingUtil'

class BlockView extends React.Component {
    
    constructor(props) {
	super(props);
	this.state = {
	    blockNumber: props.currentBlockNumber,
	};
	this.isClockStarted = false;
	this.isReady = false;
	this.isUncap = false;
	this.startUncapBlocknum = process.env.REACT_APP_START_UNCAP_BLOCKNUM;
	this.walletUtil = new KaikasWalletUtil(getContractsSetup());
	this.cnt = 0;
    }
    
    isMintingReady() {
	return this.props.mintingStartBlock - this.state.blockNumber < 600;
    }

    isUncapReady(blockNumber) {
	if (this.startUncapBlocknum === 0) {
	    return false;
	}
	return this.startUncapBlocknum < blockNumber;
    }
    
    tick() {
	if (this.isClockStarted) {
	    this.cnt++;
	    if (this.cnt > 10) {
		this.walletUtil
		    .getBlockNumber()
		    .then(blockNumber => {
			this.setState(state => ({
			    blockNumber: blockNumber
			}));
		    });
		this.cnt = 0;
	    } else {
		this.setState(state => ({
		    blockNumber: this.state.blockNumber + 1
		}));
	    }
	    if (!this.isReady && this.isMintingReady()) {
		this.props.handleMintingReady(true);
	    }
	    if (!this.isUncap && this.isUncapReady(this.state.blockNumber)) {
		this.isUncap = true;
		this.props.handleUncapReady(true);
	    }
	    this.isReady = this.isMintingReady();
	}
    }
    
    componentDidMount() {
	this.interval = setInterval(() => this.tick(), 1000);
    }    
    
    componentDidUpdate(prevProps) {
	if (prevProps.currentBlockNumber
	    !== this.props.currentBlockNumber) {
	    // console.log(this.props.currentBlockNumber);
	    this.isClockStarted = true;
	    this.setState({
		blockNumber: this.props.currentBlockNumber,
	    });
	    this.isUncap = this.isUncapReady(this.props.currentBlockNumber);
	    this.props.handleUncapReady(this.isUncap);
	}
    }
    
    componentWillUnmount() {
	clearInterval(this.interval);
    }
    
    render() {
	const {
	    blockNumber
	} = this.state
	return (
	    <Row>
		<h3>
		    MINTING BLOCK NUMBER
		</h3>
		<div className="block-line">
		    <h4 className="block-title">
			현재 블록 높이<br/>
			<span>Current Block</span>
		    </h4>
		    <h2 className="block-content">
			#{blockNumber}
		    </h2>
		</div>
		<div className="block-line">
		    <h4 className="block-title">
			민팅 시작 높이<br/>
			<span>Minting Start</span>
		    </h4>
		    <h2 className="block-content current">
                        #{this.props.mintingStartBlock}
		    </h2>
		</div>
	    </Row>    
	);	
    }
    
}

export default BlockView
