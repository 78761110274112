import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import MintPage from './pages/MintPage';
import FreeMintPage from './pages/FreeMintPage';

function App() {
    return (
	    <div>
	        <div className="App">
	          {/* <MintPage /> */}
                  <FreeMintPage/>
	        </div>
	    </div>
    );
}

export default App;
