import React from 'react'

import Row from 'react-bootstrap/Row'

import Button from 'react-bootstrap/Button'
import NumericInput from 'react-numeric-input';
import CircularProgress from '@mui/material/CircularProgress';

import "./MintView.css"

class MintView extends React.Component {

    constructor(props) {
	super(props);
	this.state = {
	    mintCount:props.mintCount,
	    mintingStartReady:props.mintingStartReady,
	    captchaValidated:props.captchaValidated,
	    handleClick:props.handleClick,
	    handleMintCountChange:props.handleMintCountChange,
	    account:props.account,
	    balance:props.balance,
	    isWLOngoing:props.isWLOngoing,
	    isWLSatisfied:props.isWLSatisfied,
	    remainedCount:props.remainedCount,
	    mintLimitPerTRDefault:props.mintLimitPerTRDefault,
	    mintLimitPerWallet:props.mintLimitPerWallet,
	    isMintingOngoing: props.isMintingOngoing,
	    uncapStartReady: props.uncapStartReady,
	};
    }

    componentDidUpdate(prevProps) {
	if (prevProps !== this.props) {
	    this.setState(this.props);
	}
    }

    getButtonView(args) {
	const {
	    ready,
	    disabled,
	    handleClick
	} = args;
	let mintingButton = (
	    <div>
	 	<Button
		    variant="primary"
		    className="btn-mint ready"
		    disabled={disabled}
		    onClick={handleClick}>
		    START MINTING
		</Button>
	    </div>
	);
	if (!ready) {
	    mintingButton = (
    	        <div>
	 	    <Button
		        variant="primary"
		        className="btn-mint"
		        disabled={disabled}
		        onClick={handleClick}>
		        START MINTING
		    </Button>
	        </div>
	    );
	}
	return mintingButton;
    }

    getWalletStateView(args) {
	const {
	    boldContent,
	    content
	} = args;
	let walletState = (
	    <div className="line-balance">
		<span>{boldContent}</span> {content}
	    </div>
	);
	return walletState;
    }

    render() {
	const {
	    mintCount,
	    mintingStartReady,
	    captchaValidated,
	    handleClick,
	    handleMintCountChange,
	    account,
	    balance,
	    isWLOngoing,
	    isWLSatisfied,
	    remainedCount,
	    mintLimitPerTRDefault,
	    mintLimitPerWallet,
	    isMintingOngoing,
	    uncapStartReady,
	} = this.state;

	if (!captchaValidated) {
	    return (
		<div></div>
	    );
	}

	// DEFAULT
	let mintingButton = this.getButtonView({
	    ready: true,
	    disabled: true,
	    handleClick: undefined
	});
	let walletState = this.getWalletStateView({
	    boldContent: "",
	    content: "Connecting..",
	});
	let progressView = (<div></div>);

	// Account Check
	if (account !== '') {
	    walletState = this.getWalletStateView({
		boldContent: "MY BALANCE",
		content: balance.toLocaleString() + "ETH",
	    });
	}
	// WL Check
	let enableMinting =
	    (isWLOngoing && isWLSatisfied) || (!isWLOngoing);
	if ((enableMinting && mintingStartReady) || (mintingStartReady && uncapStartReady)) {
	    mintingButton = this.getButtonView({
		ready: false,
		disabled: false,
		handleClick: handleClick,
	    });

	}
	if (!enableMinting) {
	    walletState = this.getWalletStateView({
		boldContent: "",
		content: "This wallet is not in the whitelist.",
	    });
	}

	let mintLimitPerTRFinal = mintLimitPerTRDefault;
	// if (isWLOngoing && isWLSatisfied) {
	if (isWLOngoing) {
	    if (mintLimitPerWallet < mintLimitPerTRDefault) {
		mintLimitPerTRFinal = mintLimitPerWallet;
	    }
	}
	if (uncapStartReady) {
	    mintLimitPerTRFinal = mintLimitPerTRDefault;
	    walletState = this.getWalletStateView({
		boldContent: "",
		content: "The whitelist minting limit will be released.",
	    });
	}
	if (mintLimitPerTRFinal <= 0) {
	    mintingButton = this.getButtonView({
		ready: true,
		disabled: true,
		handleClick: undefined,
	    });
	    walletState = this.getWalletStateView({
		boldContent: "",
		content: "할당된 수량의 민팅을 완료하였거나 대상이 아닌 지갑입니다. You have completed the maximum amount of minting available, or your wallet does not have permission",
	    });
	}

	if (remainedCount <= 0) {
	    walletState = this.getWalletStateView({
		boldContent: "",
		content: "SOLD OUT",
	    });
	}
	if (isMintingOngoing) {
	    mintingButton = this.getButtonView({
		ready: true,
		disabled: true,
		handleClick: undefined,
	    });
	    progressView = (
		<div className="circle-progress">
		    <CircularProgress/>
		</div>
	    );
	    walletState = this.getWalletStateView({
		boldContent: "",
		content: "Currently minting...",
	    });
	}
	if (!window.klaytn && !window.ethereum) {
	    mintingButton = this.getButtonView({
		ready: true,
		disabled: true,
		handleClick: undefined,
	    });
	    walletState = this.getWalletStateView({
		boldContent: "",
		content: "There is no wallet installed on your browser",
	    });
	}

	let provider = localStorage.getItem('selectedProvider')
	if (account === '' || provider === '' || !provider) {
	    mintingButton = this.getButtonView({
		ready: true,
		disabled: true,
		handleClick: undefined,
	    });
	    walletState = this.getWalletStateView({
		boldContent: "",
		content: "지갑을 연결해주세요. Please connect your wallet",
	    });
	}

	return (
	    <Row>
		<div>
		    <div className="mint-title">
			<h3>
			    AMOUNT
			</h3>
			<h4 className="mint-desc">
			    새로고침 없이 진행됩니다.
			    (No need to refresh)
			</h4>
		    </div>
		    <div>
			<NumericInput
			    mobile
			    min={1}
	                    max={Number(mintLimitPerTRFinal)}
			    className="form-control"
			    value={mintCount}
			    onChange={handleMintCountChange}
			/>
		    </div>
		</div>
		<div>
		    {progressView}
		    {mintingButton}
		    {walletState}
		</div>
	    </Row>
	);
    }
}
export default MintView
